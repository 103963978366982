//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState, mapActions } from 'vuex';

export default {
  name: 'TranscriptMenuItem',
  computed: {
    ...mapState('mediaPlayer/captions', {
      selected: 'transcript',
    }),
  },
  methods: {
    ...mapActions('mediaPlayer/captions', ['toggleTranscript']),
    /**
     * @public
     */
    focus() {
      this.$nextTick(() => this.$refs.kCheckbox.focus());
    },
  },
  $trs: {
    transcript: 'Transcript',
  },
};

