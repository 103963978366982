//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'MediaPlayerMenu',
  data: function() {
    return {
      open: false,
    };
  },
  methods: {
    /**
     * @public
     * @return {Element}
     */
    contentEl() {
      return this.$refs.contentEl;
    },
    /**
     * @public
     */
    show() {
      this.open = true;
    },
    /**
     * @public
     */
    hide() {
      this.open = false;
    },
    /**
     * @public
     * @return {boolean}
     */
    showing() {
      return this.open;
    },
  },
};

