//
//
//
//
//
//
//
//
//


import MediaPlayerMenu from '../MediaPlayerMenu';
import mediaPlayerMenuMixin from '../mixins/MediaPlayerMenu';

export default {
  name: 'CaptionsMenu',
  components: { MediaPlayerMenu },
  mixins: [mediaPlayerMenuMixin],
};

