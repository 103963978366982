//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import videojs from 'video.js';

const SPEAKER_REGEX = /<v ([^>]+)>/i;

export default {
  name: 'TranscriptCue',
  props: {
    cue: Object,
    mediaDuration: Number,
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    speaker() {
      return this.cue.text.match(SPEAKER_REGEX)
        ? this.cue.text.replace(SPEAKER_REGEX, '$1')
        : null;
    },
    startTime() {
      return videojs.formatTime(this.cue.startTime, this.mediaDuration);
    },
    style() {
      const activeStyles = this.active
        ? {
            backgroundColor: this.$themePalette.grey.v_300,
            borderLeftColor: this.$themeTokens.video,
          }
        : {};

      return Object.assign(activeStyles, {
        ':hover': {
          backgroundColor: this.$themePalette.grey.v_200,
        },
        ':focus': this.$coreOutline,
      });
    },
    text() {
      return this.cue.text.replace(SPEAKER_REGEX, '');
    },
    textStyle() {
      return {
        'border-color': this.$themeTokens.fineLine,
      };
    },
    timeStyle() {
      return {
        color: this.$themeTokens.annotation,
      };
    },
  },
  methods: {
    triggerSeekEvent() {
      this.$emit('seek', this.cue.startTime);
      this.$el.focus({
        preventScroll: true,
      });
    },
    /**
     * @public
     */
    duration() {
      return this.cue.endTime - this.cue.startTime;
    },
    /**
     * @public
     */
    height() {
      return this.$el.offsetHeight;
    },
    /**
     * @public
     */
    offsetTop() {
      return this.$el.offsetTop;
    },
    /**
     * @public
     */
    focus() {
      return this.$el.focus();
    },
  },
  $trs: {
    title: 'Seek to {startTime}',
    timeLabel: 'Transcript cue start time',
    textLabel: {
      message: 'Transcript cue caption text',
      context:
        '\nThis string is used to describe the container where the caption appears to help those using screen readers assistive technology.\n\nYou could also translate it as "Text of the current caption".\n\n\n',
    },
  },
};

