//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import vue from 'kolibri.lib.vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import videojs from 'video.js';
import throttle from 'lodash/throttle';
import { languageIdToCode } from 'kolibri.utils.i18n';
import commonCoreStrings from 'kolibri.coreVue.mixins.commonCoreStrings';
import responsiveElementMixin from 'kolibri.coreVue.mixins.responsiveElementMixin';
import responsiveWindowMixin from 'kolibri.coreVue.mixins.responsiveWindowMixin';
import Settings from '../utils/settings';
import { ReplayButton, ForwardButton } from './customButtons';
import MediaPlayerFullscreen from './MediaPlayerFullscreen';
import MimicFullscreenToggle from './MediaPlayerFullscreen/mimicFullscreenToggle';
import MediaPlayerTranscript from './MediaPlayerTranscript';
import CaptionsButton from './MediaPlayerCaptions/captionsButton';
import LanguagesButton from './MediaPlayerLanguages/languagesButton';

import audioIconPoster from './audio-icon-poster.svg';

const GlobalLangCode = vue.locale;

const componentsToRegister = {
  MimicFullscreenToggle,
  ReplayButton,
  ForwardButton,
  CaptionsButton,
  LanguagesButton,
};

Object.entries(componentsToRegister).forEach(([name, component]) =>
  videojs.registerComponent(name, component)
);

export default {
  name: 'MediaPlayerIndex',
  components: { MediaPlayerFullscreen, MediaPlayerTranscript },
  mixins: [commonCoreStrings, responsiveWindowMixin, responsiveElementMixin],
  data: () => ({
    dummyTime: 0,
    progressStartingPoint: 0,
    lastUpdateTime: 0,
    loading: true,
    playerVolume: 1.0,
    playerMuted: false,
    playerRate: 1.0,
    // TODO figure if this prop is supposed to be used
    // defaultLangCode: GlobalLangCode,
    updateContentStateInterval: null,
    isFullscreen: false,
  }),
  computed: {
    ...mapState('mediaPlayer/captions', {
      transcript: state => state.transcript,
      captionLanguage: state => state.language,
    }),
    ...mapGetters('mediaPlayer/captions', {
      captionTracks: 'tracks',
    }),
    posterSources() {
      const posterFileExtensions = ['png', 'jpg'];
      return this.thumbnailFiles.filter(file =>
        posterFileExtensions.some(ext => ext === file.extension)
      );
    },
    audioPoster() {
      if (this.posterSources.length) {
        return this.posterSources[0].storage_url;
      }
      return audioIconPoster;
    },
    videoSources() {
      const videoFileExtensions = ['mp4', 'webm', 'ogg'];
      return this.files.filter(file => videoFileExtensions.some(ext => ext === file.extension));
    },
    audioSources() {
      const audioFileExtensions = ['mp3'];
      return this.files.filter(file => audioFileExtensions.some(ext => ext === file.extension));
    },
    trackSources() {
      const trackFileExtensions = ['vtt'];
      return this.supplementaryFiles.filter(file =>
        trackFileExtensions.some(ext => ext === file.extension)
      );
    },
    isVideo() {
      return this.videoSources.length;
    },
    savedLocation() {
      if (this.extraFields && this.extraFields.contentState) {
        return this.extraFields.contentState.savedLocation;
      }
      return 0;
    },
    progressStyle() {
      return {
        '.vjs-play-progress': {
          backgroundColor: this.$themeTokens.primary,
          '::before': {
            color: this.$themeTokens.primary,
          },
        },
      };
    },
    transcriptVisible() {
      return this.transcript && !this.loading && this.captionTracks.length > 0;
    },
  },
  watch: {
    isFullscreen() {
      this.resizePlayer();
    },
  },
  created() {
    this.settings = new Settings({
      playerVolume: this.playerVolume,
      playerMuted: this.playerMuted,
      playerRate: this.playerRate,
    });
  },
  mounted() {
    this.initPlayer();
    window.addEventListener('resize', this.throttledResizePlayer);
  },
  beforeDestroy() {
    clearInterval(this.updateContentStateInterval);
    this.updateContentState();

    this.$emit('stopTracking');
    window.removeEventListener('resize', this.throttledResizePlayer);
    this.resetState();
  },
  methods: {
    ...mapActions('mediaPlayer', ['setPlayer', 'resetState']),
    isDefaultTrack(langCode) {
      if (!this.captionLanguage) {
        return false;
      }

      const shortLangCode = languageIdToCode(langCode);
      const shortGlobalLangCode = languageIdToCode(this.captionLanguage);

      return shortLangCode === shortGlobalLangCode;
    },
    initPlayer() {
      this.$nextTick(() => {
        this.player = videojs(this.$refs.player, this.getPlayerConfig(), this.handleReadyPlayer);
        this.setPlayer(this.player);
      });
    },
    getPlayerConfig() {
      const videojsConfig = {
        fluid: false,
        fill: true,
        controls: true,
        textTrackDisplay: true,
        bigPlayButton: true,
        preload: 'metadata',
        playbackRates: [0.5, 1.0, 1.25, 1.5, 2.0],
        controlBar: {
          children: [
            { name: 'PlayToggle' },
            { name: 'ReplayButton' },
            { name: 'ForwardButton' },
            { name: 'CurrentTimeDisplay' },
            { name: 'ProgressControl' },
            { name: 'TimeDivider' },
            { name: 'DurationDisplay' },
            {
              name: 'VolumePanel',
              inline: false,
            },
            { name: 'PlaybackRateMenuButton' },
            {
              name: 'CaptionsButton',
              settings: this.settings,
            },
            {
              name: 'LanguagesButton',
              settings: this.settings,
            },
            { name: 'MimicFullscreenToggle' },
          ],
        },
        language: GlobalLangCode,
        languages: {
          [GlobalLangCode]: {
            Play: this.$tr('play'),
            Pause: this.$tr('pause'),
            Replay: this.$tr('replay'),
            Forward: this.$tr('forward'),
            'Current Time': this.$tr('currentTime'),
            'Duration Time': this.$tr('durationTime'),
            Loaded: this.$tr('loaded'),
            Progress: this.coreString('progressLabel'),
            'Progress Bar': this.$tr('progressBar'),
            Fullscreen: this.$tr('fullscreen'),
            'Non-Fullscreen': this.$tr('nonFullscreen'),
            Mute: this.$tr('mute'),
            Unmute: this.$tr('unmute'),
            'Playback Rate': this.$tr('playbackRate'),
            Captions: this.$tr('captions'),
            'captions off': this.$tr('captionsOff'),
            Transcript: this.$tr('transcript'),
            'Transcript off': this.$tr('transcriptOff'),
            Languages: this.$tr('languages'),
            'Volume Level': this.$tr('volumeLevel'),
            'A network error caused the media download to fail part-way.': this.$tr(
              'networkError'
            ),
            'The media could not be loaded, either because the server or network failed or because the format is not supported.': this.$tr(
              'formatError'
            ),
            'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.': this.$tr(
              'corruptionOrSupportError'
            ),
            'No compatible source was found for this media.': this.$tr('sourceError'),
            'The media is encrypted and we do not have the keys to decrypt it.': this.$tr(
              'encryptionError'
            ),
          },
        },
      };

      if (!this.isVideo) {
        videojsConfig.poster = this.audioPoster;
      }

      return videojsConfig;
    },
    handleReadyPlayer() {
      const startTime = this.savedLocation >= this.player.duration() ? 0 : this.savedLocation;
      this.player.currentTime(startTime);
      this.player.play();

      this.player.on('play', () => {
        this.focusOnPlayControl();
        this.setPlayState(true);
      });
      this.player.on('pause', () => {
        this.focusOnPlayControl();
        this.setPlayState(false);
        this.updateContentState();
      });
      this.player.on('timeupdate', this.updateTime);
      this.player.on('seeking', this.handleSeek);
      this.player.on('volumechange', this.throttledUpdateVolume);
      this.player.on('ratechange', this.updateRate);
      this.player.on('ended', () => this.setPlayState(false));

      this.$watch('elementWidth', this.updatePlayerSizeClass);
      this.updatePlayerSizeClass();
      this.resizePlayer();

      this.useSavedSettings();
      this.loading = false;
      this.$refs.player.tabIndex = -1;

      this.updateContentStateInterval = setInterval(this.updateContentState, 30000);
    },
    resizePlayer() {
      if (this.isFullscreen) {
        this.$refs.wrapper.style.height = `100%`;
        return;
      }

      const aspectRatio = 16 / 9;
      const adjustedHeight = this.$refs.wrapper.clientWidth * (1 / aspectRatio);

      this.$refs.wrapper.style.height = `${adjustedHeight}px`;
    },
    throttledResizePlayer: throttle(function resizePlayer() {
      this.resizePlayer();
    }, 300),
    throttledUpdateVolume: throttle(function updateVolume() {
      this.updateVolume();
    }, 1000),
    updateVolume() {
      this.settings.playerVolume = this.player.volume();
      this.settings.playerMuted = this.player.muted();
    },
    updateRate() {
      this.settings.playerRate = this.player.playbackRate();
    },
    useSavedSettings() {
      this.playerVolume = this.settings.playerVolume;
      this.playerMuted = this.settings.playerMuted;
      this.playerRate = this.settings.playerRate;
      this.player.volume(this.playerVolume);
      this.player.muted(this.playerMuted);
      this.player.playbackRate(this.playerRate);
    },
    focusOnPlayControl() {
      this.$refs.wrapper.getElementsByClassName('vjs-play-control')[0].focus();
    },
    handleSeek() {
      // record progress before updating the times,
      // to capture any progress that happened pre-seeking
      this.recordProgress();

      // now, update all the timestamps to set the new time location
      // as the baseline starting point
      this.dummyTime = this.player.currentTime();
      this.lastUpdateTime = this.dummyTime;
      this.progressStartingPoint = this.dummyTime;
    },
    updateTime() {
      // skip out of here if we're currently seeking,
      // so we don't update this.dummyTime before calculating old progress
      if (this.player.seeking()) {
        return;
      }
      this.dummyTime = this.player.currentTime();
      if (this.dummyTime - this.lastUpdateTime >= 5) {
        this.recordProgress();
        this.lastUpdateTime = this.dummyTime;
      }
    },
    setPlayState(state) {
      // avoid recording progress if we're currently seeking,
      // as timers are in an intermediate state
      if (!this.player.seeking()) {
        this.recordProgress();
      }
      if (state === true) {
        this.$emit('startTracking');
      } else {
        this.$emit('stopTracking');
      }
    },
    recordProgress() {
      this.$emit(
        'addProgress',
        Math.max(
          0,
          (this.dummyTime - this.progressStartingPoint) / Math.floor(this.player.duration())
        )
      );
      this.progressStartingPoint = this.dummyTime;
    },
    updatePlayerSizeClass() {
      this.player.removeClass('player-medium');
      this.player.removeClass('player-small');
      this.player.removeClass('player-tiny');

      if (this.elementWidth < 600) {
        this.player.addClass('player-medium');
      }
      if (this.elementWidth < 480) {
        this.player.addClass('player-small');
      }
      if (this.elementWidth < 360) {
        this.player.addClass('player-tiny');
      }
    },
    updateContentState() {
      const currentLocation = this.player.currentTime();
      let contentState;
      if (this.extraFields) {
        contentState = {
          ...this.extraFields.contentState,
          savedLocation: currentLocation || this.savedLocation,
        };
      } else {
        contentState = { savedLocation: currentLocation || this.savedLocation };
      }
      this.$emit('updateContentState', contentState);
    },
  },
  $trs: {
    replay: 'Go back 10 seconds',
    // Pulled from https://github.com/videojs/video.js/blob/master/lang/en.json
    forward: 'Go forward 10 seconds',
    play: 'Play',
    pause: 'Pause',
    currentTime: 'Current time',
    durationTime: 'Duration time',
    loaded: 'Loaded',
    progressBar: 'Progress bar',
    fullscreen: 'Fullscreen',
    nonFullscreen: 'Non-fullscreen',
    mute: 'Mute',
    unmute: 'Unmute',
    playbackRate: 'Playback rate',
    captions: 'Captions',
    captionsOff: 'Captions off',
    transcript: {
      message: 'Transcript',
      context:
        '\nRefers to the option to present the captions (subtitles) of the video in the form of the interactive transcript.',
    },
    transcriptOff: 'Transcript off',
    languages: 'Languages',
    volumeLevel: 'Volume level',
    networkError: 'A network error caused the media download to fail part-way',
    formatError:
      'The media could not be loaded, either because the server or network failed or because the format is not supported',
    corruptionOrSupportError:
      'The media playback was aborted due to a corruption problem or because the media used features your browser did not support',
    sourceError: 'No compatible source was found for this media',
    encryptionError: 'The media is encrypted and we do not have the keys to decrypt it',
  },
};

