//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'LanguagesIcon',
};

