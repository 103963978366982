//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapActions, mapState } from 'vuex';

export default {
  name: 'LanguagesMenuItem',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('mediaPlayer/captions', ['language']),
    selected() {
      return this.language === this.value;
    },
  },
  methods: {
    ...mapActions('mediaPlayer/captions', ['setLanguage']),
    /**
     * @public
     */
    focus() {
      // When focused, radio button should activate
      this.setLanguage(this.value);
      this.$nextTick(() => this.$refs.kRadioButton.focus());
    },
  },
};

